 .pageContainer {
 background-color: white;
 height: calc(100vh - 200px);
} 

/*
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  /*background-color: #8c52ff;
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.logo {
  width: 70px;
  height: 70px;
}

.login {
  border: 1px solid rgb(51, 51, 216);
  padding: 12px 30px;
  border-radius: 50px;
  background-color: white;
  cursor: pointer;
  font-size: 15px;
  outline: none;
  margin-right: 20px;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}*/

.hero {
  height:calc(100vh - 200px); 
  margin: auto;
  
  line-height: 60px;
  display:flex ;
  flex-direction: column;
  align-items: center;
}

.hero h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

.paragraph {
  margin-top: 20px;
  text-align: center;
  line-height: 20px;
  color: gray;
}

.imgContainer {
  max-width:500px;
}
.hero img {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*
.footerText {
  border-top: solid 1px rgb(219, 219, 219);
  width: 80%;
  margin: 0px auto;
  text-align: center;
  padding: 25px;
  text-align: center;
}*/



/* Small devices (portrait tablets and large phones, 600px and smaller) */
@media only screen and (max-width: 600px) {

  .hero {
    width: 100%;
  }

  .hero img {
    max-width: 500px;
  }

  .hero h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 50px;
    text-align: center;
  }

}
